<template lang="pug">
    .LeaderboardBlock
        .lb-btns
            .lb-btn-1(:class="{'lb-btn_select':!showList}" @click="showList=false") {{ $t('leaderboard.leadersOfTheDay') }}
            .lb-btn-2(:class="{'lb-btn_select':showList}" @click="showList=true") {{ $t('leaderboard.leadersOfTheMonth') }}
        .lb-wrap    
            .lb-left(v-if="topTodayWins.length>0" :class="{'lb-list-hide':showList}")
                .lb-title {{ $t('leaderboard.leadersOfTheDay') }}
                .lb-empty( v-if="topTodayWins.length===0") {{ $t('leaderboard.listEmpty') }}
                .lb-list(v-else)
                    .lb-top(v-for='(top,index) in topTodayWins')
                        .lb-place(:data-iter="index+1")
                        .lb-info
                            .lb-user {{ top.user }}
                            .lb-game {{ getTitle(top) }}
                        .lb-win(:data-win="moneyFormat(top.win, top.currency_separator, top.currency_minor_unit)" :data-currency="moneyCurency(top.currency)")
            .lb-center(v-if="topMonthWins.length>0" :class="{'lb-list-hide':!showList}")
                .lb-title {{ $t('leaderboard.leadersOfTheMonth') }}
                .lb-empty( v-if="topMonthWins.length===0") {{ $t('leaderboard.listEmpty') }}
                .lb-list(v-else)
                    .lb-top(v-for='(top,index) in topMonthWins')
                        .lb-place(:data-iter="index+1")
                        .lb-info
                            .lb-user {{ top.user }}
                            .lb-game {{ getTitle(top) }}
                        .lb-win(:data-win="moneyFormat(top.win, top.currency_separator, top.currency_minor_unit)" :data-currency="moneyCurency(top.currency)")
            .lb-right(v-if="topMonthGames.length>0")
                .lb-title {{ $t('leaderboard.topMonthlyGames') }}
                .lb-empty( v-if="topMonthGames.length===0") {{ $t('leaderboard.listEmpty') }}
                .lb-list(v-else)
                    .lb-top(v-for='(top,index) in topMonthGames')
                        .lb-col-first
                            .lb-cover
                                object(type="image/svg+xml" :data-game_id="'game_'+top.game_id" :data="url_icon(top.image_file)" @load="on_svg_load" )
                        .lb-col-last
                            .lb-game {{ getTitle(top) }}
                            .lb-win(:data-win="moneyFormat(top.win, top.currency_separator, top.currency_minor_unit)" :data-currency="moneyCurency(top.currency)")
                            .lb-btn( @click="doRunGame(top.game_id)") {{ $t('leaderboard.play') }}
</template>

<script>

import LeaderboardBlock from './LeaderboardBlock.vue';

export default {
   extends: LeaderboardBlock,
};
</script>

<style lang="scss">
@import "@/assets/styles/leaderboard/leaderboard-block_sw.scss";
</style>