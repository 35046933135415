<template lang="pug">
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import pureMoneyFormat from '@/utils/currency';

export default {
  name: 'LeaderboardBlock',
  props: {
    pageSizeTop: {
      type: Number,
      default: 3,
      required: false,
    },
    pageSizeWin: {
      type: Number,
      default: 6,
      required: false,
    }
  },
  data: () => ({
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: pureMoneyFormat,
    //Флаг отображения списка
    showList: false,
    isMobile: false,
  }),

  computed: {
    ...mapGetters({
      userLanguageCode: 'user/getLanguageCode',
      topMonthWins: 'games/getTopMonthWins',
      topTodayWins: 'games/getTopTodayWins',
      topMonthGames: 'games/getTopMonthGames',
      userBalance: 'user/getBalance',
      isAuthUser: 'auth/isAuthUser',
      token: 'games/getLaunchToken'
    })
  },

  methods: {
    ...mapActions(
        {
          fetchTopMonthWins: 'games/fetchTopMonthWins',
          fetchTopTodayWins: 'games/fetchTopTodayWins',
          fetchTopMonthGames: 'games/fetchTopMonthGames',
          fetchLaunchToken: 'games/fetchLaunchToken'
        }
    ),
    ...mapMutations({
      SET_LAUNCH_TOKEN: 'games/SET_LAUNCH_TOKEN',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),

    async fetch() {
      const params = {
        limit: 10
      };
      if (this.isAuthUser) {
        if (this.userBalance.currency) {
          params.currency = this.userBalance.currency;
        }
      }
      params.limit = this.pageSizeWin;
      await this.fetchTopMonthWins(params);
      await this.fetchTopTodayWins(params);
      params.limit = this.pageSizeTop;
      await this.fetchTopMonthGames(params);
    },

    async doRunGame(gameId) {
      if (!this.isMobile) {
        this.doOpenGamePage(gameId);
      } else {
        if (!this.isAuthUser) {
          window.location.href = this.url_run(gameId, true);
        } else {
          if (this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
          await this.fetchLaunchToken(gameId);
          if (this.token.length > 0) {
            const url_game_run = this.url_run(gameId, false);
            this.SET_LAUNCH_TOKEN('');
            window.location.href = url_game_run;
          }
          this.SET_LAUNCH_TOKEN('');
        }
      }
    },

    url_run(gameId, isDemo = false) {
      var searchParams = new URLSearchParams(
          {
            r: 'x-slots/start-game-no-cookie',
            demo: 0,
            homeless: 1
          }
      );
      searchParams.append('game_id', gameId);
      searchParams.append('lang', this.userLanguageCode);
      searchParams.append('back_url', window.location.href);
      searchParams.append('partner_id', process.env.VUE_APP_PARENT_ID);
      if (isDemo === true)
        searchParams.append('demo', 1);
      else
        searchParams.append('token', this.token);

      return 'http://wbgdev.ngmcasino.com/wbg_server/index.php?' + searchParams.toString();
    },

    async doOpenGamePage(gameId) {
      if (!this.isMobile) {
        this.SET_OPEN_PAGE('personalGame');
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0, 0);
      }
    },

    url_icon(image_file) {
      let out = '';
      if (image_file !== null)
        try {
          out = 'game-icons/' + image_file;
        } catch (e) {
          out = 'game-icons/no_cover.svg';
        }
      return out;
    },

    async on_svg_load(event) {
      const svg_event = event.target.contentDocument.documentElement;
      if (
          svg_event &&
          svg_event.pauseAnimations &&
          svg_event.animationsPaused() === false
      )
        svg_event.pauseAnimations();
    },

    getTitle(top) {
        //this.userLanguageCode ???
        return (typeof top['game_'+this.currentLanguageCodeShort] === 'string'?top['game_'+this.currentLanguageCodeShort]:'');
    },
  
    resize() {
      if (window.innerWidth >= 1280) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    }
  },

  async mounted() {
    await this.fetch();
    this.resize();
    window.addEventListener('orientationchange', this.resize, false);
    window.addEventListener('resize', this.resize, false);
  }
};
</script>

<style lang="scss">
</style>